import { map } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
	Auth,
	GoogleAuthProvider,
	signInWithPopup,
	sendPasswordResetEmail,
	UserCredential,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
} from "@angular/fire/auth";
import {
	Firestore,
	doc,
	getDoc,
	deleteDoc,
	setDoc,
	updateDoc,
	docSnapshots,
	collection,
	getDocs,
	query,
	where,
} from "@angular/fire/firestore";
import { AlertController, NavController } from "@ionic/angular";
import { Subject, lastValueFrom, from } from "rxjs";
import { Timestamp } from "../models/timestamp";
import { User } from "../models/user";
import { takeUntil } from "rxjs/operators";
import { getDownloadURL, Storage, ref } from "@angular/fire/storage";
import { SquareItem } from "../models/squareItem";
import { environment } from "src/environments/environment";
import { Functions, httpsCallable } from "@angular/fire/functions";
import { SquarePlan } from "../models/squarePlan";
import { estimateRemainingTime } from "src/app/shared/util/migrate";
import { AppUser } from "../models/appUser";

const provider = new GoogleAuthProvider();
@Injectable({
	providedIn: "root",
})
export class UserService {
	public defaultUserIcon: string = "assets/defaultProfile.jpg";
	public loggedIn: boolean = false;
	isApp: boolean = false;
	isAndroid: boolean = false;
	loggedInUser: User = new User();
	private loggedInUserPlanUnlockedItems: string[] = [];
	public appUsers: Array<AppUser> = [];
	appVersion: string = "0.0.031";
	compatibleVersion: boolean = false;
	serverVersion: string = "";
	userUpdateSubject: Subject<User>;
	public requestedRoute: string = "";
	public refreshRequestedRoute: string = "";
	public refreshRequestedTab: string = "";
	public selectedProduct: string = "";
	public loadingLogin: boolean = false;
	public authorizedUser: boolean = false;
	public shoppingCart: SquareItem = null;
	private destroyed$: Subject<boolean> = new Subject();
	private allUsersForFind: Array<User> = [];
	constructor(
		public afs: Firestore,
		public alertCtrl: AlertController,
		public afAuth: Auth,
		public httpClient: HttpClient,
		public storage: Storage,
		private fns: Functions,
		public navCtrl: NavController
	) {}

	getAppVersion() {
		return docSnapshots(doc(this.afs, "appConfig/versioning"));
	}

	async isVersionUpToDate() {
		let version: string = await this.getVersion();

		let cloudVersion = parseInt(version.replace(/\./g, "").substr(0, 5));
		let localVersion = parseInt(
			this.appVersion.replace(/\./g, "").substr(0, 5)
		);

		this.serverVersion = version;

		if (cloudVersion <= localVersion) {
			this.compatibleVersion = true;
			return true;
		} else {
			this.compatibleVersion = false;
			return false;
		}
	}

	async getVersion(): Promise<string> {
		let version = await getDoc(doc(this.afs, "appConfig/versioning"));

		if (version.exists()) {
			return version.data()["appVersion"];
		}
		return "";
	}

	async setProfilePicture(url: string, uid: string) {
		if (this.loggedInUser.profilePicture != url) {
			this.loggedInUser.profilePicture = url;
			this.loggedInUser.profilePictureUid = uid;
			this.loggedInUser.profilePictureThumb = url;
			let storageRef = ref(this.storage, `uploads/thumb@256_${uid}`);
			try {
				setTimeout(async () => {
					let url = await getDownloadURL(storageRef);
					this.loggedInUser.profilePictureThumb = url;
					console.log("UPDATING URL: ", url);
				}, 3000);
			} catch (error) {}
			await this.updateUser(this.loggedInUser);
		}
	}

	async updateProfilePictureThumb(url: string, uid: string) {
		this.loggedInUser.profilePicture = url;
		this.loggedInUser.profilePictureUid = uid;
		let storageRef = ref(this.storage, `uploads/thumb@256_${uid}`);
		try {
			let url = await getDownloadURL(storageRef);
			this.loggedInUser.profilePictureThumb = url;
			console.log("UPDATING URL: ", url);
		} catch (error) {}
		this.updateUser(this.loggedInUser);
	}

	async logUserOut() {
		this.loggedIn = false;
		this.loggedInUser = new User();

		await this.afAuth.signOut();
		window.location.reload();
	}

	getUserProfile(userUid: string) {
		const foundUser = this.allUsersForFind.find((user) => user.uid === userUid);
		if (foundUser) {
			return foundUser.profilePictureThumb == ""
				? this.defaultUserIcon
				: foundUser.profilePictureThumb;
		}
		return this.defaultUserIcon;
	}

	userSubscribe(userUid: string) {
		return docSnapshots(doc(this.afs, `users/${userUid}`)).pipe(
			map((documentData) => {
				return new User().buildUser(documentData.data() as User);
			})
		);
	}

	private async addNewUserFromResult(result: UserCredential) {
		return await this.addUser(this.createUserFromCredential(result));
	}

	createUserFromCredential(result: UserCredential) {
		let user = new User();
		user.email = result.user.email;
		user.uid = result.user.uid;
		user.role = "client";
		let names = result.user.displayName.split(" ");
		user.firstName = names.length > 0 ? names[0] : "";
		user.lastName = names.length > 1 ? names[1] : "";
		return user;
	}

	async getUser(userUid: string): Promise<User> {
		console.log("get user...");
		let loggedInUserDoc = await getDoc(doc(this.afs, `users/${userUid}`));
		let user = new User().buildUser(loggedInUserDoc.data() as User);
		if (user.uid !== "") {
			return user;
		}
		return null;
	}

	async checkUserExists(userCred: UserCredential): Promise<any> {
		try {
			const callable = httpsCallable(this.fns, "checkUserExists");

			const res$ = from(
				callable({
					email: userCred.user.email,
					uid: userCred.user.uid,
					displayName: userCred.user.displayName,
				})
			);

			return await lastValueFrom(res$);
		} catch (error) {
			console.log(error);
			return "error";
		}
	}

	private currentUserEqaulsResult(result: any, currentUser): boolean {
		return (
			result &&
			result.user &&
			result.user.uid &&
			currentUser.uid == result.user.uid
		);
	}

	resetUser(user: User) {
		const result = sendPasswordResetEmail(this.afAuth, user.email).catch(
			(err) => console.log(err)
		);

		return result;
	}

	public validateEmail(email: string) {
		const re =
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

	async logUserIn(user: User) {
		if (this.compatibleVersion) {
			let result = await signInWithEmailAndPassword(
				this.afAuth,
				user.email,
				user.password
			).catch((err) => {
				return err.message;
			});
			let currentUser = await this.afAuth.currentUser;

			if (
				result &&
				result.user &&
				result.user.uid &&
				currentUser.uid == result.user.uid
			) {
				this.loggedInUser.uid = result.user.uid;
				this.loggedIn = true;
				this.loggedInUser.emailVerified = new Timestamp(
					new Date().getTime() / 1000,
					0
				);
			} else if (
				result &&
				result.user &&
				result.user.uid &&
				currentUser.uid == result.user.uid
			) {
				this.loggedInUser.uid = result.user.uid;
				result = null;
			}

			const loggedInUserDoc = await getDoc(
				doc(this.afs, `users/${this.loggedInUser.uid}`)
			);

			console.log("fetching user...");
			if (loggedInUserDoc) {
				this.loggedInUser = new User().buildUser(
					loggedInUserDoc.data() as User
				);

				this.setAuthLevel();
				this.setUserUnlockedItems();

				this.userSub();
			}
			return result;
		} else {
			if (this.serverVersion === "") {
				return "App is offline and failed to get version.";
			}
			return `Update app or close/open new tab in browser to get app version: ${this.serverVersion}`;
		}
	}

	private setAuthLevel() {
		if (
			["admin", "payrollManager", "payroll", "manager", "employee"].includes(
				this.loggedInUser.role
			)
		) {
			this.authorizedUser = true;
		}
	}

	async getProductWithPlanId(planId: string): Promise<SquareItem> {
		try {
			let querySnapshot = await getDocs(
				query(collection(this.afs, "products"), where("planId", "==", planId))
			);
			let temp: SquareItem[] = [];
			querySnapshot.forEach((doc) => {
				temp.push(new SquareItem().buildModel(doc.data() as SquareItem));
			});

			return temp.find((t) => t.planId === planId);
		} catch (err) {
			console.log(err);
			return null;
		}
	}

	private async setUserUnlockedItems() {
		if (
			!this.loggedInUser.activeSubscription ||
			this.loggedInUser.subscriptionPlanId === ""
		) {
			this.loggedInUserPlanUnlockedItems = [];
			return;
		}
		const squarePlan = await this.getProductWithPlanId(
			this.loggedInUser.subscriptionPlanId
		);
		if (!squarePlan) return;

		this.loggedInUserPlanUnlockedItems = squarePlan.planUnlockedItems;
	}

	public isLapsUnlocked(): boolean {
		return (
			this.loggedInUserPlanUnlockedItems.find((i) => i === "lapTimes") !==
				undefined || this.loggedInUser.role === "admin"
		);
	}

	public isLiveStreamUnlocked(): boolean {
		return (
			this.loggedInUserPlanUnlockedItems.find((i) => i === "liveStream") !==
				undefined || this.loggedInUser.role === "admin"
		);
	}

	public isVideoClipsUnlocked(): boolean {
		return (
			this.loggedInUserPlanUnlockedItems.find((i) => i === "videoClips") !==
				undefined || this.loggedInUser.role === "admin"
		);
	}

	async addUser(user: User) {
		try {
			let obj = user.toObj();
			let res = await setDoc(doc(collection(this.afs, "users"), user.uid), obj);
			return true;
		} catch (err) {
			console.log(err);
			return false;
		}

		// return setDoc(doc(this.afs, `users/${user.uid}`), user.toObj())
		// 	.then((res) => {
		// 		return true;
		// 	})
		// 	.catch((err) => {
		// 		console.log("woops", err);
		// 		return false;
		// 	});
	}

	async deleteUser(user: User) {
		await deleteDoc(doc(this.afs, `users/${user.uid}`));
	}

	async updateUser(user: User) {
		try {
			console.log("+=+=+=+=UPDATE USER+=+=+=+=");
			user.dateModified = new Timestamp(new Date().getTime() / 1000, 0);

			await updateDoc(doc(this.afs, `users/${user.uid}`), user.toObj());

			return user;
		} catch (error) {
			console.log(error);
			return error;
		}
	}

	async updateUserForSignup(user: User) {
		try {
			console.log("+=+=+=+=UPDATE USER SIGNUP+=+=+=+=");
			user.dateModified = new Timestamp(new Date().getTime() / 1000, 0);

			await updateDoc(doc(this.afs, `users/${user.uid}`), {
				agreedToTerms: true,
				firstName: user.firstName,
				lastName: user.lastName,
				companyName: user.companyName,
				contactInformation:
					user.contactInformation.toObj === undefined
						? user.contactInformation
						: user.contactInformation.toObj(),
			});

			return user;
		} catch (error) {
			console.log(error);
			return error;
		}
	}

	async updateUserProfile(user: User) {
		await updateDoc(doc(this.afs, `users/${user.uid}`), {
			profilePicture: user.profilePicture,
			profilePictureUid: user.profilePictureUid,
		});
	}

	async isUserLoggedIn(): Promise<boolean> {
		try {
			// If the user is already logged in, resolve immediately
			const currentUser = await this.afAuth.currentUser;
			if (currentUser) {
				return this.fetchAndSetUserDetails(currentUser.uid);
			}

			// If the user is not logged in, wait for token changes
			return new Promise<boolean>((resolve) => {
				this.afAuth.onIdTokenChanged(async (user) => {
					if (user) {
						const success = await this.fetchAndSetUserDetails(user.uid);
						resolve(success);
					} else {
						this.handleNotLoggedIn(resolve);
					}
				});

				// Handle scenario when user is not logged in after 15 seconds
				setTimeout(() => {
					if (!this.loggedIn) {
						resolve(false);
					}
				}, 15000);
			});
		} catch (error) {
			console.log("ERROR IsUserLoggedIn", error);
			return false;
		}
	}

	async isUserSubscriptionActive(): Promise<boolean> {
		return (
			(await this.isUserLoggedIn()) && this.loggedInUser.activeSubscription
		);
	}

	private userSub() {
		this.destroyed$.next(null);
		this.userSubscribe(this.loggedInUser.uid)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((user) => {
				this.loggedInUser = user;
				this.setUserUnlockedItems();
			});
	}

	private async fetchAndSetUserDetails(uid: string): Promise<boolean> {
		this.loggedInUser.uid = uid;
		this.loggedIn = true;
		this.loggedInUser.emailVerified = new Timestamp(
			new Date().getTime() / 1000,
			0
		);
		try {
			const loggedInUserDoc = await getDoc(doc(this.afs, `users/${uid}`));

			console.log("fetching user...");
			if (loggedInUserDoc) {
				this.loggedInUser = new User().buildUser(
					loggedInUserDoc.data() as User
				);

				this.setAuthLevel();
				this.setUserUnlockedItems();

				this.userSub();

				return true;
			} else {
				return false;
			}
		} catch (err) {
			console.log(err);
			return false;
		}
	}

	private handleNotLoggedIn(resolve: (value: boolean) => void): void {
		this.loggedIn = false;
		resolve(false);
	}

	getUserName(userUid: string) {
		const foundUser = this.allUsersForFind.find((user) => user.uid === userUid);
		if (foundUser) {
			const userFullName = `${foundUser.firstName} ${foundUser.lastName}`;
			return userFullName;
		}
		return "No User Set";
	}

	async getUserNameContact(userUid: string) {
		if (userUid === "bot") {
			return "Flagger";
		}
		const foundUser = this.allUsersForFind.find((user) => user.uid === userUid);
		if (foundUser) {
			const userFullName = `${foundUser.firstName} ${foundUser.lastName}`;
			return userFullName;
		}
		return "No User Set";
	}

	async getUserProfileContact(userUid: string) {
		if (userUid === "bot") {
			return "assets/flagger-logo-new.png";
		}
		const foundUser = this.allUsersForFind.find((user) => user.uid === userUid);
		if (foundUser) {
			return foundUser.profilePictureThumb == ""
				? this.defaultUserIcon
				: foundUser.profilePictureThumb;
		}
		return this.defaultUserIcon;
	}

	navAfterLogin() {
		if (this.shoppingCart !== null) {
			this.navCtrl.navigateForward("cart");
		} else {
			this.navCtrl.navigateRoot("dashboard");
		}
	}

	async registerUser(user: User, role: string = "client") {
		if (user.email === "") {
			return {
				code: "auth/argument-error",
			};
		}
		user.email = user.email.toLocaleLowerCase();

		if (this.compatibleVersion) {
			let code = "";
			const result = await createUserWithEmailAndPassword(
				this.afAuth,
				user.email,
				user.password
			).catch((err) => {
				console.log("Error", err);
				code = err.code;
				return err.message;
			});

			if (
				code === "auth/email-already-in-use" ||
				code === "auth/argument-error"
			) {
				return {
					code: code,
				};
			}

			user.uid = result.user.uid;
			this.loggedIn = true;
			user.role = role;
			let newUser = new User();
			let userObj = newUser.buildUser(user);
			delete userObj.password;
			const addedUser = await this.addUser(userObj);

			if (result && result.user && result.user.uid && addedUser) {
				this.loggedInUser.uid = result.user.uid;
				this.loggedInUser.emailVerified = new Timestamp(
					new Date().getTime() / 1000,
					0
				);

				this.loggedInUser = user;

				this.userSub();
			} else {
				return "Error registering";
			}
			return result;
		} else {
			return `Update app or close/open new tab in browser to get app version: ${this.serverVersion}`;
		}
	}

	async getAllAppUsersFromInstance(afs: Firestore): Promise<Array<AppUser>> {
		let querySnapshot = await getDocs(query(collection(afs, "appUsers")));

		return querySnapshot.docs.reduce((arr, doc) => {
			let usr = new AppUser().buildUser(doc.data() as AppUser);

			arr.push(usr);
			return arr;
		}, []);
	}

	async setAllAppUsersGlobal(afs: Firestore) {
		console.log("get appUsers...");
		this.appUsers = [];
		this.appUsers = await this.getAllAppUsersFromInstance(afs);
	}

	getUserByEPC(EPC: string): AppUser {
		let user = this.appUsers.find((u) => u.getEpcCodes().includes(EPC));
		return user;
	}

	async getAllUsers() {
		console.log("getting all users");

		let querySnapshot = await getDocs(query(collection(this.afs, "users")));
		let temp: User[] = [];
		querySnapshot.forEach((doc) => {
			temp.push(new User().buildUser(doc.data() as User));
		});
		return temp;
	}

	public async updateAllAppUsers() {
		const startTime = Date.now();
		let allUsers = await this.getAllUsers();

		for (let index = 0; index < allUsers.length; index++) {
			const user = allUsers[index];
			console.log(user.email);
			await this.updateAppUser(user.uid);
			estimateRemainingTime(allUsers, index, startTime);
		}
		console.log("🥓🥓🥓 Complete 🥓🥓🥓");
	}

	private async updateAppUser(userUid: string) {
		try {
			let callable = httpsCallable(this.fns, "setAppUserSubscription");
			let res$ = from(
				callable({
					userUid: userUid,
				})
			);

			let res = await lastValueFrom(res$);
			console.log("res", res);

			return res;
		} catch (error) {
			console.log(error);
			return [];
		}
	}
}
